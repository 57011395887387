import React from 'react';
import Template from '../layout/Template';

const Terms = () => {
  return (
    <div className='dc-text-page'>
      <style>{`body { overflow: auto !important}`}</style>
      <Template>
        <div className='dc-text-content'>
          <div className='dc-date'>Last updated: November 30, 2016</div>
          <h3>
            Scope of Application
          </h3>
          <p>
            To get in touch with us, please write to <a href="mailto:hello@dcmn.com">hello@dcmn.com</a> or use our <a href="https://www.dcmn.com/contact" rel="noreferrer" target="_blank">contact form</a>.
          </p>
          <p>
            This legal notice refers to the following websites:
          </p>
          <ul><li><a href="https://www.dcmn.com/">https://www.dcmn.com</a> (and all subdomains)</li></ul>
          <ul><li><a href="https://twitter.com/DCMN">https://twitter.com/DCMN</a></li><li><a href="https://twitter.com/DcmnIndia">https://twitter.com/DCMNIndia</a></li><li><a href="https://www.facebook.com/dcmnglobal">https://www.facebook.com/dcmnglobal</a></li><li><a href="https://www.linkedin.com/company/dcmn">https://www.linkedin.com/company/dcmn</a></li><li><a href="https://www.linkedin.com/company/26489144/">https://www.linkedin.com/company/26489144/</a></li><li><a href="https://www.instagram.com/lifeatdcmn/">https://www.instagram.com/lifeatdcmn/</a></li></ul>

          <p>Responsible for the content of this website:</p>
          <p>
            DCMN GmbH <br />
            Boxhagener Str. 18 <br />
            10245 Berlin<br />
            Germany
          </p>
          <p>
            Phone: +49 30 9919 164 - 30
          </p>
          <p>
            Managing Directors: Andreas Dengler, Matthias Riedl
          </p>
          <p>
            AT Reg. No: DE272724971<br />
            Registered at local district court: Berlin-Charlottenburg, HRB 127810
          </p>
          <p>
            Responsible for content (§ 6 MDSTV): Andreas Dengler<br />
            Responsible for content in accordance with §5 TMG: Andreas Dengler
          </p>

          <p>Our international subsidiaries:</p>
          <p><b>FRANCE</b><br />DCMN SAS<br />91 rue du Faubourg-Saint-Honoré<br />75008 Paris<br />France<br /></p>
          <p><b>INDIA</b><br />D.C. Media Networks Pvt Ltd.<br />222, Nurture Labs<br />Indira Nagar Double Road, Domlur II Stage<br />Bangalore, 560071<br />India<br /></p>
          <p><b>THE NETHERLANDS</b><br /> <p>DCMN B.V.<br />Johan Huizingalaan 400<br />Amsterdam, 1066 JS<br />The Netherlands<br /></p></p>
          <p><b>POLAND</b><br />DCMN Sp. z o.o.<br />ul. Św. Mikołaja 19<br />50-128 Wrocław<br />Poland<br /></p>
          <p><b>SOUTH AFRICA</b><br />DCMN Marketing Solutions (PTY) Ltd.<br />7 Orphan St, 2nd Floor<br />Cape Town, Western Cape, 8001, <br />South Africa<br /></p>
          <p><b>UNITED KINGDOM</b><br />DCMN Marketing Solutions Ltd.<br />2nd Floor Waverley House, 7-12 Noel Street<br />London, W1F 8GQ<br />United Kingdom<br /></p>
          <p><b>USA
          </b><br />300 Cadman Plaza W, <br />
            Brooklyn, <br />
            NY 11201, <br />
            New York City, <br />
            United States.</p>


        </div>
      </Template>
    </div>
  )
}

export default Terms 
